import React, { Component } from 'react';
import {Switch, Route, BrowserRouter as Router, Redirect} from "react-router-dom";
import {
	CONTACT_PATH,
	DEFAULT_LANGUAGE, FAQ_PATH,
	HOME_PATH, PRINT_PASS_PATH, RATES_PATH,
	PARKING_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_RESERVATION_PATH, RELATIVE_RESERVATION_SUCCESS_PATH,
	RESERVATION_PATH, RESERVATION_SUCCESS_PATH
} from "./constants/Constants";
import {Reservation} from "./components/Reservation/Reservation";
import {NotFoundPage} from "./components/NotFoundPage";
import Home from "./components/Home/Home";
import Rates from "./components/Rates/Rates";
import {ReservationSuccess} from "./components/Reservation/ReservationSuccess";
import Faq from "./components/Faq/Faq";
import Contact from "./components/Contact/Contact";
import Parking from "./components/Parking/Parking";
import PrintPass from "./components/PrintPass/PrintPass";
import {isOpenPurchases} from "./utils";

class App extends Component {
  render() {
		if (isOpenPurchases()) {
			return (
				<Router>
					<Switch>
						<Route path={HOME_PATH} component={Home}/>
						<Route path={RATES_PATH} component={Rates}/>
						<Route path={FAQ_PATH} component={Faq}/>
						<Route path={CONTACT_PATH} component={Contact}/>
						<Route path={PARKING_PATH} component={Parking}/>
						<Route path={PRINT_PASS_PATH} component={PrintPass}/>

						<Route path={RESERVATION_SUCCESS_PATH} component={ReservationSuccess}/>
						<Route path={RESERVATION_PATH} component={Reservation}/>
						<Route exact path={"/" + RELATIVE_HOME_PATH} render={() => <Redirect to={"/" + DEFAULT_LANGUAGE + "/" + RELATIVE_HOME_PATH} />} />
						<Route exact path={"/" + RELATIVE_RESERVATION_PATH} render={() => <Redirect to={"/" + DEFAULT_LANGUAGE + "/" + RELATIVE_RESERVATION_PATH} />} />
						<Route exact path={"/" + RELATIVE_RESERVATION_SUCCESS_PATH} render={() => <Redirect to={"/" + DEFAULT_LANGUAGE + "/" + RELATIVE_RESERVATION_SUCCESS_PATH} />} />
						<Route exact path="/:locale/" render={() => <Redirect to={RELATIVE_HOME_PATH} />} />
						<Route exact path={"/"} render={() => <Redirect to={"/" + DEFAULT_LANGUAGE + "/" + RELATIVE_HOME_PATH} />} />
						<Route path={"*"} component={NotFoundPage}/>
					</Switch>
				</Router>
			);

		} else {
			return (
				<Router>
					<Switch>
						<Route path={HOME_PATH} component={Home}/>
						<Route exact path="/:locale/" render={() => <Redirect to={RELATIVE_HOME_PATH} />} />
						<Route exact path={"/"} render={() => <Redirect to={"/" + DEFAULT_LANGUAGE + "/" + RELATIVE_HOME_PATH} />} />
						<Route path={"*"} component={NotFoundPage}/>
					</Switch>
				</Router>
			);
		}

  }
}

export default App;
