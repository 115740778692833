export const RELATIVE_HOME_PATH = 'home/';
export const RELATIVE_RATES_PATH = 'rates/';
export const RELATIVE_RESERVATION_PATH = 'reservation/';
export const RELATIVE_FAQ_PATH = 'faq/';
export const RELATIVE_CONTACT_PATH = 'contact/';
export const RELATIVE_PARKING_PATH = 'parking/';
export const RELATIVE_PRINT_PASS_PATH = 'print-pass/';
export const RELATIVE_RESERVATION_SUCCESS_PATH = RELATIVE_RESERVATION_PATH + 'success/';
export const BASE_PATH = '/:locale/';

export const HOME_PATH = BASE_PATH + RELATIVE_HOME_PATH;
export const RESERVATION_PATH = BASE_PATH + RELATIVE_RESERVATION_PATH;
export const RESERVATION_SUCCESS_PATH = BASE_PATH + RELATIVE_RESERVATION_SUCCESS_PATH;

export const RATES_PATH = BASE_PATH + RELATIVE_RATES_PATH;
export const FAQ_PATH = BASE_PATH + RELATIVE_FAQ_PATH;
export const CONTACT_PATH = BASE_PATH + RELATIVE_CONTACT_PATH;
export const PARKING_PATH = BASE_PATH + RELATIVE_PARKING_PATH;
export const PRINT_PASS_PATH = BASE_PATH + RELATIVE_PRINT_PASS_PATH;



export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY;

export const OPEN_DATE = Date.parse("2024-06-01T00:00:00Z");
export const CLOSE_DATE = Date.parse("2024-09-30T23:59:59Z");


export const LANGUAGES = {
    IT: 'it',
    EN: 'en'
};
export const URL_REQUEST_LICENSE_PLATE_PARAM  = '{licensePlate}';
export const URL_PARAM_PAYMENT_INTENT_SECRET  = 'p';

export const CLIENT_SECRET_STRIPE_KEY = "client_secret_stripe";

export const DEFAULT_LANGUAGE = LANGUAGES.IT;
// URL
export const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;
export const URL_API_PARKING_PASS_REQUEST = 'parking_pass_request/';
export const URL_API_VEHICLE = 'vehicle/';
export const URL_API_REPRINT_PASS = URL_API_VEHICLE + URL_REQUEST_LICENSE_PLATE_PARAM + "/reprint/" ;

