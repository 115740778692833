import React from 'react';
import "./Reservation.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {
	Button,
	Dimmer,
	Divider,
	Form,
	Header,
	Icon,
	Input,
	Loader,
	Modal,
	Select,
	Step
} from "semantic-ui-react";
import {
	RELATIVE_HOME_PATH,
	RELATIVE_FAQ_PATH, URL_PARAM_PAYMENT_INTENT_SECRET
} from "../../constants/Constants";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
	STRIPE_PUBLIC_KEY
} from "../../constants/Constants";
import {postParkingPassRequest} from "../../api";
import {StorageHelper} from "../../utils/StorageHelper";
import {InjectedCheckoutForm} from "./InjectCheckoutForm";
import {
	DocumentTypeOptions,
	PARKING_PASS_TYPE0,
	PARKING_PASS_TYPE1, PARKING_PASS_TYPE1_EXT, PARKING_PASS_TYPE2,
	PARKING_PASS_TYPE3,
	PARKING_PASS_TYPE4, PARKING_PASS_TYPE5,
	ParkingPassTypeOptions
} from "../../constants/Key";
import {COUNTRY_OPTIONS} from "../../constants/CountriesData";



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY );


export class Reservation extends React.Component {
	constructor(props) {
		super(props);
		const urlParams = new URLSearchParams(window.location.search);
		let payment_intent_param = urlParams.get(URL_PARAM_PAYMENT_INTENT_SECRET);
		let step_number = 1;

		if (payment_intent_param) {
			step_number = 4;
		}
		this.state = {loading: false, modalErrorOpen:false, modalPaymentOpen: false, first_name: "", last_name: "", identity_doc_type: "", identity_doc_number: "", identity_doc_released_by: "",
			identity_doc_date_released: "", identity_doc_date_expiration: "", email: "", email_confirm: "", phone: "", license_plate: "", parking_pass_type: "", payment_client_secret:"", price_amount:"",  price_currency:"",
			residence_place:"", residence_address:"", residence_address_number:"", birthday_place:"", birthday_date:"",  certificate_media:null, identity_doc_front_media: null, identity_doc_back_media: null, vehicle_registration_media: null,
			birthday_place_error: false, birthday_date_error: false, residence_place_error:false, residence_address_error:false, residence_address_number_error:false,
			cf:"", nationality:"IT",
			certificate_media_error:false, cf_error:false, nationality_error:false,
			first_name_error: false, last_name_error: false, identity_doc_type_error: false, identity_doc_number_error: false, identity_doc_released_by_error: false,
			identity_doc_date_released_error: false, identity_doc_date_expiration_error: false, email_error: false, phone_error: false, license_plate_error: false, parking_pass_type_error: false,
			identity_doc_front_media_error: false, identity_doc_back_media_error: false, vehicle_registration_media_error: false,
			activation_date: new Date(),
			activation_date_error: false,
			step_number: step_number,
			terms_checked: false,
			terms_error: false,
			vehicle_registration_media_back: null,
			vehicle_registration_media_back_error: false,
			payment_intent_param: payment_intent_param,
			modal_info_visible: true
		};
	}

	componentDidMount() {
    }

	 error_required = {
		content: 'Questo campo è obbligatorio',
		pointing: 'above',
	};

	cf_error = {
		content: 'Codice fiscale non valido',
		pointing: 'above',
	};
	email_error = {
		content: 'Le due email non combaciano',
		pointing: 'above',
	};
	handleChange = (event, {name, value}) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
			console.log(this.state)
		}
	};

	handleCloseInfoModal = () => this.setState({ modal_info_visible: false });
	handleOpenError = () => this.setState({ modalErrorOpen: true });
	handleCloseError = () => this.setState({ modalErrorOpen: false });
	toggleCheckBox = () => this.setState((prevState) => ({ terms_checked: !prevState.terms_checked }));

	onClickNext = async () => {

		if (StorageHelper.getStripeClientSecret()) {
			this.setState({step_number:4});
		} else if (this.state.step_number === 1) {
			let error = false;
			this.setState({email_error: false, phone_error: false, parking_pass_type_error: false, certificate_media_error:false, activation_date_error:false, terms_error:false});
			if (this.state.email === "") {
				this.setState({ email_error: this.error_required });
				error=true
			}

			if (this.state.email !== this.state.email_confirm) {
				this.setState({ email_error: this.email_error });
				error=true
			}


			if (this.state.phone === "") {
				this.setState({ phone_error: this.error_required });
				error=true
			}

			if (this.state.parking_pass_type === "") {
				this.setState({ parking_pass_type_error: this.error_required });
				error=true
			}

			if ((this.state.parking_pass_type === PARKING_PASS_TYPE1 ||
				this.state.parking_pass_type === PARKING_PASS_TYPE1_EXT ||
				this.state.parking_pass_type === PARKING_PASS_TYPE2 ||
				this.state.parking_pass_type === PARKING_PASS_TYPE3 ||
				this.state.parking_pass_type === PARKING_PASS_TYPE4) && this.state.certificate_media == null) {
				this.setState({ certificate_media_error: this.error_required });
				error=true
			}
			if (this.state.activation_date === '' && (this.state.parking_pass_type === PARKING_PASS_TYPE1 || this.state.parking_pass_type === PARKING_PASS_TYPE1_EXT || this.state.parking_pass_type === PARKING_PASS_TYPE2 || this.state.parking_pass_type === PARKING_PASS_TYPE3 || this.state.parking_pass_type === PARKING_PASS_TYPE4)) {
				this.setState({ activation_date_error: this.error_required });
				error=true
			}

			console.log(this.state.terms_checked)
			if (!this.state.terms_checked) {
				this.setState({ terms_error: this.error_required });
				error=true
			}

			if (!error) {
				this.setState({step_number:this.state.step_number + 1})
			}

		} else if (this.state.step_number === 2) {
			this.setState({first_name_error: false, last_name_error: false, identity_doc_type_error: false, identity_doc_number_error: false, identity_doc_released_by_error: false,
				identity_doc_date_released_error: false, identity_doc_date_expiration_error: false,
				identity_doc_front_media_error: false, identity_doc_back_media_error: false, birthday_place_error: false, residence_place_error:false, residence_address_error:false
				, residence_address_number_error:false, cf_error: false, birthday_date_error: false })
			let error = false;

			if (this.state.first_name === "") {
				this.setState({ first_name_error: this.error_required });
				error=true
			}
			if (this.state.last_name === "") {
				this.setState({ last_name_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_type === "") {
				this.setState({ identity_doc_type_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_number === "") {
				this.setState({ identity_doc_number_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_released_by === "") {
				this.setState({ identity_doc_released_by_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_date_released === "") {
				this.setState({ identity_doc_date_released_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_date_expiration === "") {
				this.setState({ identity_doc_date_expiration_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_front_media === null) {
				this.setState({ identity_doc_front_media_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_back_media === null) {
				this.setState({ identity_doc_back_media_error: this.error_required });
				error=true
			}

			if (this.state.birthday_place === "") {
				this.setState({ birthday_place_error: this.error_required });
				error=true
			}
			if (this.state.birthday_date === "") {
				this.setState({ birthday_date_error: this.error_required });
				error=true
			}

			if (this.state.residence_place === "") {
				this.setState({ residence_place_error: this.error_required });
				error=true
			}
			if (this.state.residence_address === "") {
				this.setState({ residence_address_error: this.error_required });
				error=true
			}

			if (this.state.residence_address === "") {
				this.setState({ residence_address_number_error: this.error_required });
				error=true
			}

			if (this.state.identity_doc_date_expiration === "") {
				this.setState({ identity_doc_date_expiration_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_date_expiration === "") {
				this.setState({ identity_doc_date_expiration_error: this.error_required });
				error=true
			}
			if (this.state.identity_doc_date_expiration === "") {
				this.setState({ identity_doc_date_expiration_error: this.error_required });
				error=true
			}

			if (this.state.nationality === "") {
				this.setState({ nationality_error: this.error_required });
				error=true
			}

			if (this.state.nationality === "IT") {
				if (this.state.cf === "") {
					this.setState({ cf_error: this.error_required });
					error=true
				} else if (this.state.cf.length !== 16) {
					this.setState({ cf_error: this.cf_error });
					error=true
				}
			}



			if (!error) {
				this.setState({step_number:this.state.step_number + 1})
			}

		} else if (this.state.step_number === 3) {
			console.log("handle step 3")
			this.setState({license_plate_error: false, vehicle_registration_media_error: false});
			let error = false;

			if (this.state.license_plate === "") {
				this.setState({ license_plate_error: this.error_required });
				error=true
			}
			if (this.state.vehicle_registration_media === null) {
				this.setState({ vehicle_registration_media_error: this.error_required });
				error=true
			}


			if (!error) {
				console.log("step without error")
				this.setState({loading: true});
				try {
					const responseRequest = await postParkingPassRequest(this.state.first_name, this.state.last_name, this.state.identity_doc_type, this.state.identity_doc_number, this.state.identity_doc_released_by,
						this.state.identity_doc_date_released, this.state.identity_doc_date_expiration, this.state.email, this.state.phone, this.state.license_plate, this.state.parking_pass_type,
						this.state.identity_doc_front_media, this.state.identity_doc_back_media, this.state.vehicle_registration_media, this.state.certificate_media, this.state.nationality, this.state.residence_place,
						this.state.residence_address, this.state.residence_address_number, this.state.cf, this.state.activation_date, this.state.vehicle_registration_media_back);
					console.log(responseRequest.payment_client_secret);
					this.setState({
						loading: false,
						payment_client_secret: responseRequest.payment_client_secret,
						price_amount: responseRequest.price_amount,
						price_currency: responseRequest.price_currency,
					});

					StorageHelper.setStripeClientSecret(responseRequest.payment_client_secret);

					this.setState({step_number:this.state.step_number + 1})

				} catch (e) {
					this.setState({loading: false});
					this.handleOpenError()
				}
			}
		} else {
			this.setState({step_number:this.state.step_number + 1});
		}

	};
	onClickBack = async () => {
		this.setState({step_number:this.state.step_number - 1})
	};



	render() {
		const fileChangeFront = (event) => {
			this.setState({identity_doc_front_media:event.target.files[0]})
		};

		const fileChangeCertification = (event) => {
			this.setState({certificate_media:event.target.files[0]})
		};

		const fileChangeBack = (event) => {
			this.setState({identity_doc_back_media:event.target.files[0]})
		};

		const fileChangeCar = (event) => {
			this.setState({vehicle_registration_media:event.target.files[0]})
		};

		const fileChangeCarBack = (event) => {
			this.setState({vehicle_registration_media_back:event.target.files[0]})
		};

		return (
			<div id="reservation">
				<HeaderCustom/>

				<Modal
					open={this.state.modal_info_visible}
					onClose={this.handleCloseInfoModal}
					size='small'
				>
					<Header icon='info' content='Informazione' />
					<Modal.Content>
						<h2>Prima di procedere con l'invio della richiesta ti ricordiamo che:</h2>
						<p>1. La persona che effettua la richiesta deve essere l'effettivo proprietario del veicolo.</p>
						<p>2. Nella carta di circolazione deve essere chiaramente indicato il proprietario del veicolo.</p>
						<p>3. In caso di richiesta di abbonamento per residenti, il tuo documento di identità deve indicare un indirizzo presso il Comune di Arzachena.</p>
						<p>4. In caso di richiesta di abbonamento per non residenti ma proprietari di immobili siti ad Arzachena è richiesta una prova di possesso dell'immobile (es: ricevuta pagamento IMU, TARI).</p>
					</Modal.Content>
					<Modal.Actions>
						<Button className='btn btn-primary btn-lg btn-block' onClick={this.handleCloseInfoModal}>
							<Icon name='checkmark' /> Ho capito
						</Button>
					</Modal.Actions>
				</Modal>
				<div class="bg-f6f6f3">
					<div class="container">
						<div class="home-howto">
							<Dimmer active={this.state.loading} inverted>
								<Loader content='Caricamento...' />
							</Dimmer>


							<Modal size={'tiny'} open={this.state.modalErrorOpen} onClose={this.close}>
								<Header color='red' icon='info' content='Errore Richiesta' />
								<Modal.Content>
									<p>E' stato riscontrato un problema durante la comunicazione col server, riprovare più tardi</p>
								</Modal.Content>
								<Modal.Actions>
									<Button
										positive
										icon='checkmark'
										labelPosition='right'
										content='OK'
										onClick={this.handleCloseError}
									/>
								</Modal.Actions>
							</Modal>
							<h1>Acquista Abbonamento Parcheggi</h1>
							<p>Completa tutti gli step del modulo presente in questa pagina per inviare la tua richiesta di abbonamento parcheggi. Tutte le informazioni richieste sono obbligatorie. Ti ricordiamo che dopo aver inviato la tua richiesta riceverai una risposta via email con l'esito della stessa entro 24 ore.</p>
							<p>Se hai bisogno di assistenza, ti consigliamo di visionare la pagina delle domande frequenti <a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH} target="_blank" rel="noopener noreferrer" >cliccando su questo link</a>.</p>

							<Step.Group fluid stackable='tablet'>
								<Step active={this.state.step_number === 1}>
									<Icon name='list alternate' />
									<Step.Content>
										<Step.Title>1. Generali</Step.Title>
									</Step.Content>
								</Step>
								<Step disabled={this.state.step_number < 2} active={this.state.step_number === 2}>
									<Icon name='user' />
									<Step.Content>
										<Step.Title>2. Identità</Step.Title>
									</Step.Content>
								</Step>
								<Step disabled={this.state.step_number < 3} active={this.state.step_number === 3}>
									<Icon name='car' />
									<Step.Content>
										<Step.Title>3. Veicolo</Step.Title>
									</Step.Content>
								</Step>
								<Step disabled={this.state.step_number < 4} active={this.state.step_number === 4}>
									<Icon name='credit card' />
									<Step.Content>
										<Step.Title>4. Pagamento</Step.Title>
									</Step.Content>
								</Step>
							</Step.Group>

							<Form autoComplete="off">
								<div style={{display:this.state.step_number === 1 ? 'block' : 'none'}}>

                                    <Form.Field
                                        name='parking_pass_type'
                                        error={this.state.parking_pass_type_error}
                                        onChange={this.handleChange}
                                        control={Select}
                                        options={ParkingPassTypeOptions}
                                        label={{ children: 'Tariffa abbonamento*', htmlFor: 'form-select-control-pass-type' }}
                                        placeholder='Tariffa'
									/>
                                    <Form.Field
                                        className={'display-block-child'}
                                        locale="it-IT"
                                        name='activation_date'
                                        format='DD/MM/YYYY'
                                        error={this.state.activation_date_error}
                                        onChange={this.handleChange}
										value={this.state.activation_date}
                                        id='form-input-control-activation-date'
                                        control={SemanticDatepicker}
                                        label="Data di attivazione dell'abbonamento"
                                    />
                                    <div className="alert alert-info" style={{display: this.state.parking_pass_type === PARKING_PASS_TYPE1  ? 'block' : 'none'}}>
										Hai selezionato la tariffa <b>Titolari di un'attività nell'ambito del Comune di Arzachena</b>. Assicurati di caricare un documento nel campo Carica Documento quì sotto che attesti la titolarità di un'azienda con sede nel Comune di Arzachena.
                                    </div>
									<div className="alert alert-info" style={{display: this.state.parking_pass_type === PARKING_PASS_TYPE1_EXT  ? 'block' : 'none'}}>
										Hai selezionato la tariffa <b>Non residenti con contratto di lavoro nell'ambito del Comune di Arzachena</b>. Assicurati di caricare un documento nel campo Carica Documento quì sotto che attesti la tua posizione lavorativa nel Comune di Arzachena (es: contratto assunzione).
									</div>
                                    <div className="alert alert-info" style={{display: (this.state.parking_pass_type === PARKING_PASS_TYPE2 || this.state.parking_pass_type === PARKING_PASS_TYPE3 || this.state.parking_pass_type === PARKING_PASS_TYPE4)   ? 'block' : 'none'}}>
                                        Hai selezionato la tariffa <b>Non residenti proprietari di immobili o ospiti di albergo</b>. Nel
                                        prossimo passaggio assicurati di caricare un documento che attesti la proprietà di un
                                        immobile (TARI, IMU) o il soggiorno presso una struttura ricettiva sita nel Comune di Arzachena (<a href="https://www.comunearzachena.it/files/472/Parcheggi/1810/autocertificazione-PER-ABBONAMENTO-parcheggi-2020.doc" target="_blank" rel="noopener noreferrer" >Scarica Modello Autocertificazione</a>).
                                    </div>
                                    <div className="alert alert-info" style={{display: this.state.parking_pass_type === PARKING_PASS_TYPE0 ? 'block' : 'none'}}>
                                        Hai selezionato la tariffa <b>Residenti</b>. Nel prossimo passaggio assicurati di caricare un
                                        documento di identità recante un indirizzo di residenza nel Comune di Arzachena.
                                    </div>
                                    <div className="alert alert-info" style={{display: this.state.parking_pass_type === PARKING_PASS_TYPE5 ? 'block' : 'none'}}>
                                        Hai selezionato la tariffa <b>Residenti nei comuni limitrofi senza sbocco a mare</b>. Nel
                                        prossimo passaggio assicurati di caricare un documento di identità che attesti la tua
                                        residenza in un Comune limitrofo senza sbocco a mare.
                                    </div>

									<Form.Field error={this.state.certificate_media_error} style={{display:(this.state.parking_pass_type === PARKING_PASS_TYPE1 || this.state.parking_pass_type === PARKING_PASS_TYPE1_EXT ||  this.state.parking_pass_type === PARKING_PASS_TYPE2 || this.state.parking_pass_type === PARKING_PASS_TYPE3 || this.state.parking_pass_type === PARKING_PASS_TYPE4) ? 'block' : 'none'}}>
										<Header as="label" className={'label-custom'}>
											Carica Documento*
										</Header>
										<Button className="btn btn-default" as="label" htmlFor="file-certificate-media" type="button">
											<Button.Content visible >
												{!this.state.certificate_media ? <Icon name='upload'  /> : <Icon name='check' />}
											</Button.Content>
										</Button>
										<input type="file" id="file-certificate-media" hidden onChange={fileChangeCertification} />
									</Form.Field>

                                    <Form.Group widths='equal' >
										<Form.Field
											name='email'
											error={this.state.email_error}
											onChange={this.handleChange}
											id='form-input-control-email'
											control={Input}
											label='Email*'
											placeholder='esempio@mail.it'
										/>
										<Form.Field
											name='email_confirm'
											error={this.state.email_error}
											onChange={this.handleChange}
											id='form-input-control-confirm-email'
											control={Input}
											label='Conferma Email*'
											placeholder='esempio@mail.it'

										/>
                                        <Form.Field
                                            name='phone'
                                            error={this.state.phone_error}
                                            onChange={phone => this.setState({ phone })}
                                            country='it'
                                            id='form-input-control-phone'
                                            control={PhoneInput}
                                            label='Numero*'
                                        />
									</Form.Group>

                                    <Divider hidden/>

									<Form.Checkbox
                                        className="check-box-terms"
										name="terms_checked"
										inline
                                        label={"Procedendo con la navigazione su parcheggiarzachena.it, dichiaro di:"}
										error={this.state.terms_error}
										onChange={this.toggleCheckBox}
									>
									</Form.Checkbox>
                                    <div id="multiline-checkbox">
                                        1. Accettare le Condizioni di utilizzo e la Privacy Policy del sito.
                                        <br />2. Avere preso visione e accettare le specifiche clausole dell'<a href="https://www.comunearzachena.it/files/472/Parcheggi/1810/autocertificazione-PER-ABBONAMENTO-parcheggi-2020.doc" target="_blank" rel="noopener noreferrer" >autocertificazione</a> e sul <a href="https://www.comunearzachena.it/files/472/Parcheggi/1811/Consenso-al-trattamento-dei-dati-personali-2020.pdf" target="_blank" rel="noopener noreferrer" >trattamento dei dati personali</a> ai sensi e per gli effetti degli art. 1341 e 1342 c.c..
                                    </div>

								</div>


								<div style={{display:this.state.step_number === 2 ? 'block' : 'none'}}>

									<Form.Group widths='equal'>
										<Form.Field
											name='first_name'
											onChange={this.handleChange}
											id='form-input-control-first-name'
											control={Input}
											label='Nome*'
											placeholder='Nome'
											error={this.state.first_name_error}

										/>
										<Form.Field
											name='last_name'
											error={this.state.last_name_error}
											onChange={this.handleChange}
											id='form-input-control-last-name'
											control={Input}
											label='Cognome*'
											placeholder='Cognome'
										/>
										<Form.Field
											name='birthday_place'
											onChange={this.handleChange}
											id='form-input-control-first-name'
											control={Input}
											label='Nato a*'
											placeholder='Roma'
											error={this.state.birthday_place_error}

										/>

										<Form.Field
											className={'display-block-child'}
											locale="it-IT"
											name='birthday_date'
											format='DD/MM/YYYY'
											error={this.state.birthday_date_error}
											onChange={this.handleChange}
											id='form-input-control-birthday-date'
											control={SemanticDatepicker}
											label='Nato il*'
										/>

									</Form.Group>
									<Form.Group widths='equal'>
										<Form.Field
											name='nationality'
											error={this.state.nationality_error}
											onChange={this.handleChange}
											control={Select}
											value={this.state.nationality}
											options={COUNTRY_OPTIONS}
											label={{ children: 'Nazionalità*', htmlFor: 'form-select-control-nationality' }}
											placeholder='Tipo'
											search
											searchInput={{ id: 'form-select-control-nationality' }}
										/>
										<Form.Field
											name='residence_place'
											onChange={this.handleChange}
											id='form-input-control-residence-place'
											control={Input}
											label='Residente in*'
											placeholder='Arzachena'
											error={this.state.residence_place_error}
										/>
										<Form.Field
											name='residence_address'
											onChange={this.handleChange}
											id='form-input-control-residence-address'
											control={Input}
											label='Indirizzo di residenza*'
											placeholder='Viale Costa Smeralda'
											error={this.state.residence_address_error}
										/>
										<Form.Field
											name='residence_address_number'
											onChange={this.handleChange}
											id='form-input-control-first-name'
											control={Input}
											label='Numero civico*'
											placeholder='12'
											error={this.state.residence_address_number_error}
										/>

									</Form.Group>
									<Form.Group style={{display:this.state.nationality === "IT" ? 'block' : 'none'}}
									>
										<Form.Field
											name='cf'
											onChange={this.handleChange}
											id='form-input-control-cf'
											control={Input}
											label='Codice Fiscale*'
											placeholder='AAAAA98ASDASDASD'
											error={this.state.cf_error}
										/>
									</Form.Group>
									<Form.Group widths='equal'>
										<Form.Field
											name='identity_doc_type'
											error={this.state.identity_doc_type_error}
											onChange={this.handleChange}
											control={Select}
											options={DocumentTypeOptions}
											label={{ children: 'Tipo Documento*', htmlFor: 'form-select-control-document-type' }}
											placeholder='Tipo'
											search
											searchInput={{ id: 'form-select-control-document-type' }}
										/>
										<Form.Field
											name='identity_doc_number'
											error={this.state.identity_doc_number_error}
											onChange={this.handleChange}
											id='form-input-control-number-doc'
											control={Input}
											label='Numero Documento*'
											placeholder='AA 1234567'
										/>
										<Form.Field
											name='identity_doc_released_by'
											error={this.state.identity_doc_released_by_error}
											onChange={this.handleChange}
											id='identity_doc_released_by'
											control={Input}
											label='Rilasciato da*'
											placeholder='Comune di XXXXXXX'
										/>
									</Form.Group>
									<Form.Group widths='equal'>
										<Form.Field
											className={'display-block-child'}
											locale="it-IT"
											name='identity_doc_date_released'
											format='DD/MM/YYYY'
											error={this.state.identity_doc_date_released_error}
											onChange={this.handleChange}
											id='form-input-control-released-date'
											control={SemanticDatepicker}
											label='Rilasciato il*'
										/>
										<Form.Field
											className={'display-block-child'}
											locale="it-IT"
											name='identity_doc_date_expiration'
											format='DD/MM/YYYY'
											error={this.state.identity_doc_date_expiration_error}
											onChange={this.handleChange}
											id='form-input-control-expired-date'
											control={SemanticDatepicker}
											label='Scadenza*'
										/>

										<Form.Field error={this.state.identity_doc_front_media_error}>
											<Header as="label" className={'label-custom'}>
												Carica Fronte Documento*
											</Header>
											<Button  as="label" htmlFor="file-identity-doc-front" type="button">
												<Button.Content visible >
													{!this.state.identity_doc_front_media ? <Icon name='upload'  /> : <Icon name='check' />}
												</Button.Content>
											</Button>
											<input type="file" id="file-identity-doc-front" hidden onChange={fileChangeFront} />

										</Form.Field>
										<Form.Field error={this.state.identity_doc_back_media_error}>
											<Header as="label" className={'label-custom'}>
												Carica Retro Documento*
											</Header>
											<Button  as="label" htmlFor="file-identity-doc-back" type="button">
												<Button.Content visible >
													{!this.state.identity_doc_back_media ? <Icon name='upload'  /> : <Icon name='check' />}
												</Button.Content>
											</Button>
											<input type="file" id="file-identity-doc-back" hidden onChange={fileChangeBack} />
										</Form.Field>
									</Form.Group>

								</div>

								<div style={{display:this.state.step_number === 3 ? 'block' : 'none'}}>

									<Form.Group widths='equal'>

										<Form.Field
											name='license_plate'
											error={this.state.license_plate_error}
											onChange={this.handleChange}
											id='form-input-control-license-plate'
											control={Input}
											label='Targa*'
											placeholder='AA123AA'
										/>

										<Form.Field error={this.state.vehicle_registration_media_error}>
											<Header as="label" className={'label-custom'}>
												Carica Libretto Di Circolazione*
											</Header>
											<Button  as="label" htmlFor="file-car-registration" type="button">
												<Button.Content visible >
													{!this.state.vehicle_registration_media ? <Icon name='upload'  /> : <Icon name='check' />}
												</Button.Content>
											</Button>
											<input type="file" id="file-car-registration" hidden onChange={fileChangeCar} />
										</Form.Field>

										<Form.Field error={this.state.vehicle_registration_media_back_error}>
											<Header as="label" className={'label-custom'}>
												Carica Retro Libretto Di Circolazione*
											</Header>
											<Button  as="label" htmlFor="file-car-registration-back" type="button">
												<Button.Content visible >
													{!this.state.vehicle_registration_media_back ? <Icon name='upload'  /> : <Icon name='check' />}
												</Button.Content>
											</Button>
											<input type="file" id="file-car-registration-back" hidden onChange={fileChangeCarBack} />
										</Form.Field>
									</Form.Group>
								</div>

								<div style={{display:this.state.step_number === 4 ? 'block' : 'none'}}>
									<Elements stripe={stripePromise} fluid>
										<InjectedCheckoutForm payment_client_secret={this.state.payment_client_secret}
															  price_amount={this.state.price_amount}
															  price_currency={this.state.price_currency}/>
									</Elements>
								</div>

								<Button style={{display:this.state.step_number !== 4 ? 'block' : 'none'}} fluid primary type='submit' onClick={this.onClickNext}>Avanti</Button>
								{(!this.state.payment_intent_param) && <Button style={{display:this.state.step_number !== 1 ? 'block' : 'none'}} fluid default type='submit' onClick={this.onClickBack}>Indietro</Button>}
							</Form>
							<p>
								*I campi contrassegnati da asterisco sono obbligatori
							</p>
							<a class="btn btn-default btn-lg btn-gohome btn-block" href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Annulla</a>
						</div>
					</div>
				</div>
				<FooterCustom/>
			</div>
		);
	}
}