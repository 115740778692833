import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import {Button, Dimmer, Loader, Message} from "semantic-ui-react";
import {StorageHelper} from "../../utils/StorageHelper";
import {RELATIVE_RESERVATION_SUCCESS_PATH, URL_PARAM_PAYMENT_INTENT_SECRET} from "../../constants/Constants";
import {withRouter} from "react-router-dom";

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        let payment_intent_param = urlParams.get(URL_PARAM_PAYMENT_INTENT_SECRET);

        this.state = {loading: false, error: false, errorMessage:"Errore generico, annulla e riprovare", payment_intent_param: payment_intent_param,
            attempts: 0 }
    }
    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        let payment_intent_secret = StorageHelper.getStripeClientSecret();
        if (this.state.payment_intent_param) {
            payment_intent_secret = this.state.payment_intent_param
        }

        console.log(payment_intent_secret)


        const {stripe, elements} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        this.setState({loading: true});
        console.log(this.props)
        try {
            const result = await stripe.confirmCardPayment(payment_intent_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                }
            });
            if (result.error) {
                console.log(result.error)
                this.setState({error: true, attempts: (this.state.attempts + 1)});
                if (this.state.attempts >= 3 ) {
                    StorageHelper.removeStripeClientSecret();
                    window.location.reload();
                }


                if (result.error.type === "card_error") {
                    this.setState({errorMessage: result.error.message});
                }
            } else {
                // The payment has been processed!
                console.log(result.paymentIntent.status);
                StorageHelper.removeStripeClientSecret();
                if (result.paymentIntent.status === 'requires_capture') {
                    this.props.history.push("/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_SUCCESS_PATH);
                }

            }
        } catch (e) {
            console.log(e)
            this.setState({error: true, attempts: (this.state.attempts + 1)});
            if (this.state.attempts >= 3 ) {
                StorageHelper.removeStripeClientSecret();
                window.location.reload();
            }
            this.setState({error: true});

        }
        this.setState({loading: false});



    };

    render() {
        return (
            <div>
                <Dimmer active={this.state.loading} inverted>
                    <Loader content='Caricamento...' />
                </Dimmer>

                <form onSubmit={this.handleSubmit}>
                    <CardSection />

                    {this.state.error && <Message negative visible={false}>
                        <Message.Header>Problema riscontrato durante il pagamento</Message.Header>
                        <p>{this.state.errorMessage}</p>
                    </Message>}

                    <Button fluid primary disabled={!this.props.stripe}>Conferma</Button>
                </form>
            </div>

        );
    }
}

export default withRouter(CheckoutForm);