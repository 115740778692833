import React from 'react';
import './Footer.scss';


export class FooterCustom extends React.Component {

	render() {
		return (

			<div class="footer">
				<div class="container">
					<ul class="list-inline">
						<li><a href="https://www.iubenda.com/privacy-policy/55924156" target="_blank" rel="noopener noreferrer" >Privacy Policy</a></li>
						<li><a href="https://servizi.comunearzachena.it/portale/delibere/delibereelenco.aspx?DELI=5906" target="_blank" rel="noopener noreferrer" >Delibera N. 106 del 09/05/2023</a></li>
						<li class="hidden"><a href="www.termini.it">Termini</a></li>
						{/*<li><a href="https://www.comunearzachena.it/files/472/Parcheggi/1850/TABELLA-PARCHEGGI-A-PAGAMENTO-2020-def.pdf" target="_blank" rel="noopener noreferrer">Determina Comunale</a></li>*/}
						{/*<li><a href="https://www.comunearzachena.it/files/472/Parcheggi/1810/autocertificazione-PER-ABBONAMENTO-parcheggi-2020.doc" target="_blank" rel="noopener noreferrer">Autocertificazione 2020</a></li>*/}
						{/*<li><a href="https://www.comunearzachena.it/files/472/Parcheggi/1811/Consenso-al-trattamento-dei-dati-personali-2020.pdf" target="_blank" rel="noopener noreferrer">Consenso trattamento dati 2020</a></li>*/}
					</ul>
					<p>Parcheggi Arzachena è una piattaforma di vendita per abbonamenti parcheggi. Il servizio è operato da <a  rel="noopener noreferrer" href="https://www.gesecoarzachena.it" target="_blank">Ge.se.co. surl</a>.</p>
				</div>
			</div>

		);
	}
}

