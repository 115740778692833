export const PARKING_PASS_TYPE0 = "REST";
export const PARKING_PASS_TYPE1 = "NRLSE";
export const PARKING_PASS_TYPE1_EXT = "NRLSE_EXT";
export const PARKING_PASS_TYPE2 = "NRCSE";
export const PARKING_PASS_TYPE3 = "NRCME";
export const PARKING_PASS_TYPE4 = "NRCST";
export const PARKING_PASS_TYPE5 = "RCLST";

export const ParkingPassTypeOptions = [
    { key: PARKING_PASS_TYPE0, text: '1. RESIDENTI (SOLO PERSONE) - STAGIONALE (€15)', value: PARKING_PASS_TYPE0 },
    { key: PARKING_PASS_TYPE1, text: '2. TITOLARI di un\'attività nell\'ambito del Comune di Arzachena. Abbonamento valido solo nei centri urbani di Baja Sardinia e Porto Cervo - MENSILE (€70)', value: PARKING_PASS_TYPE1 },
    { key: PARKING_PASS_TYPE1_EXT, text: '3. NON RESIDENTI con regolare contratto di lavoro nell\'ambito del Comune di Arzachena. Abbonamento valido solo nei centri urbani di Baja Sardinia e Porto Cervo - MENSILE (€70)', value: PARKING_PASS_TYPE1_EXT },
    { key: PARKING_PASS_TYPE2, text: '4. NON RESIDENTI proprietari di immobili e/o ospiti di alberghi - SETTIMANALE (€40)', value: PARKING_PASS_TYPE2 },
    { key: PARKING_PASS_TYPE3, text: '5. NON RESIDENTI proprietari di immobili e/o ospiti di alberghi - MENSILE (€100)', value: PARKING_PASS_TYPE3 },
    { key: PARKING_PASS_TYPE4, text: '6. NON RESIDENTI proprietari di immobili e/o ospiti di alberghi - STAGIONALE (€180)', value: PARKING_PASS_TYPE4 },
    { key: PARKING_PASS_TYPE5, text: '7. RESIDENTI nei comuni limitrofi, senza sbocco al mare - STAGIONALE (€80)', value: PARKING_PASS_TYPE5 },
];

export const DocumentTypeOptions = [
    { key: 'C', text: 'Carta di identità', value: 'C' },
    { key: 'P', text: 'Passaporto', value: 'P' }
    ];


