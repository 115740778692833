import React from 'react';
import "./Faq.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {withRouter} from "react-router-dom";
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";

class Faq extends React.Component {

	render() {
		return (
			<div id="faq">
				<React.Fragment>	
					<HeaderCustom/>
					<div class="bg-f6f6f3">
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-md-3 hidden-xs hidden-sm">
									<div class="menu-left">
										<div class="menu-title">Acquista Abbonamento Parcheggio</div>
										<ul>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2024</a></li>
											<li class="active"><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
										</ul>
									</div>
								</div>
								<div class="col-xs-12 col-md-9">
									<div class="home-howto">
										<h1>Domande Frequenti</h1>
										<h2>Ho acquistato il mio abbonamento online, ma dopo averlo stampato ho smarrito il tagliando. Come posso ottenere un tagliando di sostituzione?</h2>
										<p>È possibile ottenere un tagliando di sostituzione cliccando su "Ristampa Abbonamento" dalla barra di navigazione del sito. Segui i semplici passaggi per ottenere un duplicato. Se hai bisogno di ulteriore assistenza, contatta il nostro ufficio al numero <a href="tel:+393339257422" target="_blank" rel="noopener noreferrer">(+39)3339257422</a> o via email all'indirizzo <a href="mailto:info@parcheggiarzachena.it" target="_blank" rel="noopener noreferrer">info@parcheggiarzachena.it</a>.</p>
										<h2>Non dispongo di una stampante poichè mi trovo ad Arzachena in vacanza. Come posso esporre il tagliando dell'abbonamento?</h2>
										<p>Nel caso di indisponibilità di una stampante, puoi chiedere alla reception della struttura ricettiva dove stai soggiornando di stampare il documento. In alternativa puoi recarti presso la nostra biglietteria sita in località Malchittu per richiedere un duplicato. Nel solo caso in cui non sia possibile stampare il tagliando dell'abbonamento, è consentito esporre provvisoriamente un documento cartaceo scritto manualmente indicante gli stessi dati presenti nel tagliando ricevuto al momento dell'acquisto.</p>
										<h2>Posso acquistare un abbonamento per un mio ospite o per un parente?</h2>
										<p>L'abbonamento può essere acquistato da chiunque purchè le informazioni inserite nella richiesta relative all'acquirente e all'automezzo rispettino i requesiti previsti dal regolamento e dal piano tariffario selezionato.</p>
										<h2>Vorrei acquistare un abbonamento ma non conosco il numero di targa. Posso procedere con l'acquisto?</h2>
										<p>La richiesta di abbonamento deve obbligatoriamente contenere il numero di targa del veicolo. Senza questa informazione non è possibile procedere con l'acquisto.</p>
										<h2>Il mio automezzo è registrato come veicolo commerciale. È possibile ottenere un abbonamento per un veicolo commerciale?</h2>
										<p>È possibile acquistare un abbonamento anche per mezzi commerciali a condizione che questi siano autovetture o motocicli e che l'intestatario dei mezzi sia residente nel Comune di Arzachena. Per ulteriori informazioni sulle condizioni relative ai veicoli commerciali, telefonare al numero <a href="tel:+393339257422" target="_blank" rel="noopener noreferrer">(+39)3339257422</a> o inviare un'email all'indirizzo <a href="mailto:info@parcheggiarzachena.it" target="_blank" rel="noopener noreferrer">info@parcheggiarzachena.it</a>.</p>
										<h2>Per errore ho digitato il numero di targa sbagliato. Come posso correggere?</h2>
										<p>Se ancora non hai completato l'invio della richiesta con il pagamento online, puoi fare clic sul pulsante Indietro e tornare allo step precedente per apportare una correzione. Se hai già effettuato l'acquisto e vuoi richiedere una modifica alla tua richiesta, telefonare al numero <a href="tel:+393339257422" target="_blank" rel="noopener noreferrer">(+39)3339257422</a> o inviare un'email all'indirizzo <a href="mailto:info@parcheggiarzachena.it" target="_blank" rel="noopener noreferrer">info@parcheggiarzachena.it</a>.</p>
										<h2>È possibile acquistare un permesso per più di una settimana alla volta?</h2>
										<p>Gli abbonamenti possono essere rilasciati per una durata di una settimana, di un mese, o per l'intera stagione (la scadenza della stagione è presente nel regolamento). Se possiedi già un abbonamento settimanale e hai bisogno di estendere la scadenza dello stesso, potrai semplicemente acquistare un nuovo abbonamento settimanale o di durata mensile o stagionale.</p>
										<h2>Devo rinnovare il mio abbonamento scaduto. Posso effettuare il rinnovo online o devo obbligatoriamente venire di persona presso la vostra biglietteria?</h2>
										<p>È possibile rinnovare l'abbonamento online. Non è necessario recarsi presso la biglietteria.</p>
										<h2>Come posso cancellare la mia richiesta?</h2>
										<p>Se ancora non hai completato l'invio della richiesta o effettuato il pagamento per la stessa, puoi fare clic sul pulsante Annulla o semplicemente abbandonare il sito internet.</p>
										<h2>È possibile ottenere un rimborso?</h2>
										<p>L'acquisto dell'abbonamento non è rimborsabile. Il concessionario del servizio di emissione degli abbonamenti non rimborserà alcun importo pagato per qualsiasi motivo, incluso, a titolo esemplificativo:
											<ul>
												<li>L'inserimento di informazioni errate nella richiesta (ad es. indirizzo, durata dell'abbonamento o targa del veicolo).</li>
												<li>L'incapacità di stampare l'abbonamento o di stampare correttamente l'abbonamento.</li>
												<li>Un cambiamento nelle circostanze che non comporta ulteriori autorizzazioni.</li>
											</ul>
										</p>
										<h2>Il mio abbonamento contiene informazioni errate. Come posso apportare delle correzioni?</h2>
										<p>Le informazioni non possono essere corrette una volta rilasciato un abbonamento.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterCustom/>
				</React.Fragment>
			</div>
		);
	}
}

export default withRouter(Faq);
