import React from 'react';
import "./Contact.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {withRouter} from "react-router-dom";
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";

class Contact extends React.Component {

	render() {
		return (
			<div id="faq">
				<React.Fragment>	
					<HeaderCustom/>
					<div class="bg-f6f6f3">
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-md-3 hidden-xs hidden-sm">
									<div class="menu-left">
										<div class="menu-title">Acquista Abbonamento Parcheggio</div>
										<ul>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2024</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
											<li class="active"><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
										</ul>
									</div>
								</div>
								<div class="col-xs-12 col-md-9">
									<div class="home-howto">
										<h1>Contatti</h1>
										<p>Ge.se.co<br />Località Malchittu, Arzachena</p>
										<p>Orari ufficio: lunedì, martedì, giovedì e venerdì dalle 9:00 alle 13:00 e il mercoledì dalle 15:00 alle 19:00.</p>
										<p>Telefono: +39 3339257422<br />Email: info@parcheggiarzachena.it</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterCustom/>
				</React.Fragment>
			</div>
		);
	}
}

export default withRouter(Contact);
