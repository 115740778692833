import React from 'react';
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {
	RELATIVE_HOME_PATH
} from "../../constants/Constants";

export class ReservationSuccess extends React.Component {

	render() {
		return (
			<div id="success">
				<HeaderCustom/>
				<div class="bg-f6f6f3">
					<div class="container">
						<div class="home-howto">
							<h1>La tua richiesta è stata inviata con successo</h1>
							<p>La tua richiesta è stata inviata con successo. Lo staff di parcheggiarzachena.it ti contatterà entro 24 ore per comunicarti l'esito della tua richiesta. Se tutte le informazioni inserite saranno corrette, riceverai un'email con il pulsante per stampare l'abbonamento direttamente da casa. In caso contrario, ti verrà comunicato la motivazione del rifiuto della tua richiesta.</p>
							<p>Ti comunichiamo inoltre che non abbiamo ancora addebitato l'importo dell'abbonamento sulla tua carta. L'addebito avverrà solo in caso di accettazione della tua richiesta. In caso contrario non verrà effettuata alcuna operazione sulla carta.</p>
							<p class="margin-bottom-40">I pagamenti su parcheggiarzachena.it sono sicuri è garantiti da Stripe.com, leader mondiale nei pagamenti elettronici.</p>
							<a class="btn btn-default btn-lg btn-block" href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Torna Alla Pagina Iniziale</a>
						</div>
					</div>
				</div>
				<FooterCustom/>
			</div>
		);
	}
}