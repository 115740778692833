import React from 'react';
import "./PrintPass.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {withRouter} from "react-router-dom";
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";
import {Button, Dimmer, Form, Input, Loader, Message, Select} from "semantic-ui-react";
import {reprintPass} from "../../api";

class PrintPass extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			license_plate_error: false,
			license_plate: "",
			payment_method_error: false,
			payment_method: "",
			last_4_digit_error: false,
			last_4_digit: "",
		}
	}

	PaymentMethodOptions = [
		{ key: 'visa', text: 'Visa', value: 'visa' },
		{ key: 'mastercard', text: 'Mastercard', value: 'mastercard' },
		{ key: 'amex', text: 'American Express', value: 'amex' },
	];

	error_required = {
		content: 'Questo campo è obbligatorio',
		pointing: 'above',
	};

	handleChange = (event, {name, value}) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
			console.log(this.state)
		}
	};

	onClickReprint = async () => {
		console.log("ciao");
		this.setState({license_plate_error: false,payment_method_error: false,last_4_digit_error: false, error: false, error_message: 'Si è verificato un errore durante la verifica'});
		let error = false;
		if (this.state.license_plate === "") {
			this.setState({ license_plate_error: this.error_required });
			error=true
		}

		if (this.state.payment_method === "") {
			this.setState({ payment_method_error: this.error_required });
			error=true
		}

		if (this.state.last_4_digit === "") {
			this.setState({ last_4_digit_error: this.error_required });
			error=true
		}
		if (!error) {
			console.log("errore non trovato");

			this.setState({loading: true});
			try {
				const responseReprint = await reprintPass(this.state.license_plate, this.state.payment_method, this.state.last_4_digit);
				window.open(responseReprint.url, '_blank');
			} catch (e) {
				if (e.response.status === 404) {
					this.setState({error: true, error_message: "Abbonamento non trovato, assicurati di aver inserito informazioni corrette"});
				}
			}
			this.setState({loading: false});

		}


	};

	render() {
		return (
			<div id="faq">
				<React.Fragment>
					<Dimmer active={this.state.loading} inverted>
						<Loader content='Loading...' />
					</Dimmer>
					<HeaderCustom/>
					<div class="bg-f6f6f3">
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-md-3 hidden-xs hidden-sm">
									<div class="menu-left">
										<div class="menu-title">Acquista Abbonamento Parcheggio</div>
										<ul>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2024</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
											<li class="active"><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
										</ul>
									</div>
								</div>
								<div class="col-xs-12 col-md-9">
									<div class="home-howto">
										<h1>Ristampa Tagliando</h1>
										<p>In caso di smarrimento di un abbonamento già acquistato puoi richiedere la ristampa del tagliano da questa pagina.</p> 
										<p>Inserisci le informazioni del veicolo e del pagamento che hai utilizzato al momento dell acquisto e premi su Ristampa Tagliando per generare un nuovo tagliando.</p>
										<Form autoComplete="off">
											<Form.Field
												name='license_plate'
												error={this.state.license_plate_error}
												onChange={this.handleChange}
												id='form-input-control-license-plate'
												control={Input}
												placeholder='AA123AA'
												label='Targa del veicolo*'
											/>
											<Form.Field
												name='payment_method'
												error={this.state.payment_method_error}
												onChange={this.handleChange}
												control={Select}
												options={this.PaymentMethodOptions}
												label={{ children: 'Seleziona metodo di pagamento utilizzato*', htmlFor: 'form-select-control-payment-method' }}
												placeholder='Metodo di pagamento'
												search
												searchInput={{ id: 'form-select-control-payment-method' }}
											/>
											<Form.Field
												name='last_4_digit'
												error={this.state.last_4_digit_error}
												onChange={this.handleChange}
												id='form-input-control-license-plate'
												control={Input}
												placeholder='XXXX'
												label='Ultime 4 cifre carta utilizzata*'
											/>

											<Message
												visible={this.state.error}
												error
												header='Errore'
												content={this.state.error_message}
											/>
											<Button className='btn btn-primary btn-lg btn-block' type='submit' onClick={this.onClickReprint}>Ristampa</Button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterCustom/>
				</React.Fragment>
			</div>
		);
	}
}

export default withRouter(PrintPass);
